import DiscordIcon from "virtual:icons/ic/baseline-discord";
import CrownIcon from "virtual:icons/lucide/crown";
import InstagramIcon from "virtual:icons/mdi/instagram";
import { Link } from "@remix-run/react";
import { Image } from "@unpic/react";
import Autoplay from "embla-carousel-autoplay";
import { useRef } from "react";
import { $path } from "remix-routes";

import LeftCharacter from "#app/assets/left-character.png";
import RightCharacter from "#app/assets/right-character.png";
import { useMediaQuery } from "#app/hooks/use-media-query";

import { Separator } from "@radix-ui/react-separator";
import { useTranslation } from "react-i18next";
import { Button } from "../button";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "../carousel";
import { Isotype } from "../isotype";

export const handle = { i18n: "home" };
export function CardsJoinSection() {
	const { t } = useTranslation("home");
	return (
		<div className="relative w-full rounded-2xl border border-red-500 p-4 lg:block">
			<div
				className="-z-10 -scale-x-100 absolute inset-0 size-full bg-repeat opacity-5"
				data-pattern="stripes"
				style={{
					background: "url('/diagonal-stripes.svg')",
				}}
			/>

			<div className="flex h-full flex-col justify-between">
				<div>
					<h2 className="font-semibold text-lg dark:text-white sm:text-2xl">{t("carrousel.joinTheCommunity.title")}</h2>
					<p className="shrink text-sm dark:text-white">{t("carrousel.joinTheCommunity.subTitle1")}</p>
					<p className="shrink text-xs dark:text-white">{t("carrousel.joinTheCommunity.subTitle2")}</p>
				</div>

				<div className="mt-3 flex items-center gap-2">
					<Button $size="icon" $variant="outline" asChild className="dark:border-red-500">
						<Link target="_blank" to="https://instagram.com/timple.ai" rel="noreferrer noopener">
							<InstagramIcon className="size-7" />
						</Link>
					</Button>
					<Button $size="icon" $variant="outline" asChild className="dark:border-red-500">
						<Link target="_blank" to="https://discord.gg/r7kJ5u28" rel="noreferrer noopener">
							<DiscordIcon className="size-7" />
						</Link>
					</Button>
				</div>
			</div>
		</div>
	);
}

export function CardsSpecialOfferSection() {
	const { t } = useTranslation("home");
	return (
		<div className="relative h-full rounded-2xl bg-gradient-to-b from-red-500 to-[#202035] p-4">
			<div className="relative z-10 flex h-full flex-col justify-between">
				<div>
					<h2 className="text-center text-lg text-white sm:text-2xl">{t("carrousel.FirstSubscriptionSale.title")}</h2>
					<h3 className="inline-flex w-full justify-center gap-2 text-center font-extrabold text-lg text-white sm:text-2xl">
						<span>{t("carrousel.FirstSubscriptionSale.subtitle1")}</span>
						<CrownIcon className="size-7" />
					</h3>
					<p className="text-center text-lg text-white sm:text-2xl">{t("carrousel.FirstSubscriptionSale.subtitle2")}</p>
				</div>

				<div className="mt-3 flex justify-center">
					<Button $size="sm" asChild className="px-4">
						<Link to={$path("/market")}>
							<span>{t("carrousel.FirstSubscriptionSale.btn")}</span>
							<Isotype className="size-5" />
						</Link>
					</Button>
				</div>
			</div>

			<Image
				alt="left character"
				className="absolute bottom-0 left-4 w-full max-w-20"
				layout="fullWidth"
				src={LeftCharacter}
			/>
			<Image
				alt="right character"
				className="absolute right-4 bottom-0 w-full max-w-20"
				layout="fullWidth"
				src={RightCharacter}
			/>
		</div>
	);
}

export function CardsCreateYourOwnCharacterSection() {
	const { t } = useTranslation(["home", "common"]);
	return (
		<div className="relative rounded-2xl border border-red-500 p-4">
			<div
				className="-z-10 absolute inset-0 size-full bg-repeat opacity-5"
				data-pattern="stripes"
				style={{
					background: "url('/diagonal-stripes.svg')",
				}}
			/>

			<Isotype className="absolute top-4 left-4 h-auto w-12 text-primary" />

			<div className="flex h-full flex-col justify-between">
				<div>
					<h2 className="text-right font-semibold text-lg dark:text-white sm:text-2xl">
						{t("home:carrousel.AiChat18.titleUnique")}{" "}
						<span className="text-primary">{t("home:carrousel.AiChat18.titleAIChat")}</span>
					</h2>
					<p className="ml-auto max-w-56 shrink text-right font-medium text-sm dark:text-white">
						{t("home:carrousel.AiChat18.subTitleUse")}{" "}
						<span className="text-primary">{t("home:carrousel.AiChat18.subTitleTimple")}</span>{" "}
						{t("home:carrousel.AiChat18.subTitleCharacter")}
						<span className="text-primary"> {t("home:carrousel.AiChat18.subTitleBuild")}</span>
						{t("home:carrousel.AiChat18.subTitledreams")}
					</p>
					<p className="inline-flex w-full shrink items-center justify-end gap-1.5 truncate font-black text-primary text-sm uppercase">
						<span>{t("common:age")}</span>
						<Separator className="h-3 bg-white" orientation="vertical" />
						<span>{t("common:personality")}</span>
						<Separator className="h-3 bg-white" orientation="vertical" />
						<span>{t("common:fantasy")}</span>
						<Separator className="h-3 bg-white" orientation="vertical" />
						<span>{t("common:description")}</span>
					</p>
				</div>

				<div className="mt-3 flex justify-end">
					<Button $size="sm" asChild className="px-4">
						<Link to={$path("/characters/create")}>
							<span>{t("common:btnCreateCharacter")}</span>
							<Isotype className="size-5" />
						</Link>
					</Button>
				</div>
			</div>
		</div>
	);
}

export function CardsDestopkSection() {
	return (
		<div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
			{/* join to the community */}
			<CardsJoinSection />

			{/* special offer */}
			<CardsSpecialOfferSection />

			{/* create your own character */}
			<CardsCreateYourOwnCharacterSection />
		</div>
	);
}

export function CardsMobileSection() {
	const plugin = useRef(Autoplay({ delay: 2000, stopOnInteraction: true }));
	return (
		<div className="w-full md:px-12 lg:px-0">
			<Carousel
				className="flex w-full justify-center justify-items-center"
				onMouseEnter={plugin.current.stop}
				onMouseLeave={plugin.current.reset}
				plugins={[plugin.current]}
			>
				<CarouselContent>
					<CarouselItem key="card-join">
						<div className="flex size-full justify-center justify-items-center p-1">
							<CardsJoinSection />
						</div>
					</CarouselItem>

					<CarouselItem key="card-special-offer">
						<div className="size-full p-2">
							<CardsSpecialOfferSection />
						</div>
					</CarouselItem>

					<CarouselItem key="card-create-your-own-character">
						<div className="p-2">
							<CardsCreateYourOwnCharacterSection />
						</div>
					</CarouselItem>
				</CarouselContent>
				<CarouselPrevious className="hidden sm:block" $size="icon" $variant="secondary" />
				<CarouselNext className="hidden sm:block" $size="icon" $variant="secondary" />
			</Carousel>
		</div>
	);
}

export function CardsSection() {
	const isDesktop = useMediaQuery("(min-width: 1024px)");
	return <>{isDesktop ? <CardsDestopkSection /> : <CardsMobileSection />}</>;
}
