import type { Character } from "@prisma/client";
import { Link } from "@remix-run/react";
import { $path } from "remix-routes";

import { useTranslation } from "react-i18next";
import { Button } from "../button";
import { CharacterCard } from "../character-card";
import { Isotype } from "../isotype";

export function RecommendedCharactersSection({
	characters,
}: {
	characters?: Array<
		Pick<Character, "avatar" | "description" | "id" | "isNsfw" | "isDeluxe" | "name" | "age" | "characterSpeakform"> & {
			creator?: {
				username: string;
			};
			updatedAt?: string;
			nMessages?: number;
		}
	> | null;
}) {
	const { t } = useTranslation(["home", "common"]);
	return (
		<section className="hidden grid-cols-6 gap-8 py-10 sm:grid md:grid-cols-12 md:gap-6">
			<div className="col-span-12 flex max-w-2xl items-center xl:col-span-6">
				<div className="flex flex-col gap-6 text-center md:items-start sm:items-center md:text-left">
					<div className="flex flex-col gap-3">
						<h1 className="font-bold text-4xl text-primary">Timple</h1>
						<h2 className="font-bold text-3xl">{t("home:recommendedCharacters.leftSection.title")}</h2>
						<p>{t("home:recommendedCharacters.leftSection.subTitle")}</p>
					</div>
					<Button asChild>
						<Link to={$path("/characters/create")}>
							<span>{t("common:btnCreateAi")} </span>
							<Isotype className="h-auto w-4" />
						</Link>
					</Button>
				</div>
			</div>
			{/* max-w-xl */}
			{/* creators side */}
			<div className="col-span-12 xl:col-span-6">
				<div className="mx-auto flex w-full items-center justify-center px-2 2xl:max-w-6xl sm:px-4">
					<div className="flex w-full flex-col justify-center gap-4 2xl:max-w-6xl sm:max-w-xl">
						<p className="text-center font-bold text-lg text-primary">
							{t("home:recommendedCharacters.rightSection.title")}
						</p>

						{characters?.length ? (
							<div className="grid grid-cols-1 gap-12 lg:grid-cols-3">
								{characters.map((character) => (
									<Link key={character.id} to={`/characters/${character.id}`}>
										<CharacterCard character={character} className="col-span-1 min-h-80" key={character.id} />
									</Link>
								))}
							</div>
						) : (
							<div className="col-span-full flex flex-col items-center gap-3 rounded-lg px-4 py-24 text-center">
								<span className="block font-semibold text-lg">
									{t("home:recommendedCharacters.rightSection.subtitle1")}
								</span>
								<span className="block text-sm opacity-80">
									{t("home:recommendedCharacters.rightSection.subtitle2")}
								</span>
								<Button asChild>
									<Link to={$path("/characters/create")}>
										<span>{t("common:btnCreateAi")}</span>
										<Isotype className="h-auto w-4" />
									</Link>
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
}
