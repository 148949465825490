import { type LoaderFunctionArgs, json } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { z } from "zod";
import { zx } from "zodix";

import { CharacterStatus, CharacterVisibility } from "@prisma/client";
import { CardsSection } from "#app/components/sections/cards";
import { FindYourCharacterSection } from "#app/components/sections/find-your-character";
import { RecommendedCharactersSection } from "#app/components/sections/recommended-characters";
import { useUser } from "#app/hooks/use-user";
import { CHARACTER_PREVIEW_SELECT, searchCharacters } from "#app/models/characters.server";
import { getUserId } from "#app/utils/auth.server";
import { prisma } from "#app/utils/db.server";
import { getNSFWSetting } from "#app/utils/nsfw.server";
import { customArrayParser } from "#app/utils/qs";

export async function loader(args: LoaderFunctionArgs) {
	const userId = await getUserId(args);
	const { request } = args;
	const filters = zx.parseQuery(
		request,
		{
			sort: z.enum(["score", "createdAt", "nLikes"]).optional(),
			isDeluxe: zx.BoolAsString.optional(),
			tags: z.union([z.string(), z.array(z.string())]).optional(),
			speakform: z.enum(["all", "first person", "third person"]).optional(),
		},
		{
			parser: customArrayParser,
		},
	);
	const nsfw = getNSFWSetting(request);
	const user = userId
		? await prisma.user.findUnique({
				where: { id: userId },
				select: {
					profile: {
						select: {
							tags: {
								select: {
									id: true,
									name: true,
								},
							},
						},
					},
				},
			})
		: null;

	const tagsFilter = Array.isArray(filters.tags) ? filters.tags : [filters.tags].filter(Boolean);
	const [tags, characters, recommendedCharacters] = await Promise.all([
		prisma.tag.findMany({
			select: {
				id: true,
				name: true,
				slug: true,
				category: true,
			},
		}),
		searchCharacters(tagsFilter, filters.isDeluxe ?? null, !!user, filters.speakform),
		user
			? prisma.character.findMany({
					where: {
						isDeleted: false,
						visibility: CharacterVisibility.PUBLIC,
						status: CharacterStatus.PUBLISHED,
						tags: {
							some: {
								id: {
									in: user.profile?.tags.map((tag) => tag.id),
								},
							},
						},
					},
					select: CHARACTER_PREVIEW_SELECT,
					take: 3,
				})
			: null,
	]);

	return json({
		filters: {
			...filters,
			sort: filters.sort || "score",
			tags: Array.isArray(filters.tags) ? filters.tags : [filters.tags],
			speakform: filters.speakform || "all",
		},
		tags,
		characters: characters.map((character) => ({
			...character,
			nMessages: character.conversations.reduce((acc, conversation) => acc + conversation._count.messages, 0),
		})),
		recommendedCharacters,
	});
}

export default function Index() {
	const { recommendedCharacters } = useLoaderData<typeof loader>();
	const user = useUser();

	return (
		<div className="p-4 lg:p-8">
			<CardsSection />
			{user ? <RecommendedCharactersSection characters={recommendedCharacters} /> : null}
			<FindYourCharacterSection />
		</div>
	);
}
